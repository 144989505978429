@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap');

html,
body {
	padding: 0;
	margin: 0;
	/* font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif; */
}

@media (min-width: 992px) {
	.container-fluid {
		display: block !important;
	}

	.navbar-brand-image {
		margin-top: 8px;
	}
}

.exo-layout .ant-layout-footer{
	display: none !important;
}

/* enable page scroll */
body {
	overflow: hidden !important;
}

.container-xl {
	max-width: unset !important;
	padding: 30px !important;
}

.page-body {
	overflow-y: auto;
	height: calc(100vh - 64px); /* spacing for footer and header */
	padding-top: 1.25rem;
	padding-bottom: 1.25rem;
	margin: 0px !important;
}

/* no link style change on hover */
a {
	text-decoration: none !important;
}

.apexcharts-yaxis-texts-g .apexcharts-text tspan {
	user-select: all;
}

.apexchart-minimal svg .apexcharts-grid,
.apexchart-minimal svg .apexcharts-xaxis.apexcharts-yaxis-inversed,
.apexchart-minimal svg .apexcharts-grid {
	display: none !important;
}
.apexchart-minimal {
	min-height: 50px !important;
}
.apexchart-minimal .apexcharts-canvas,
.apexchart-minimal svg {
	height: 40px !important;
}
.apexchart-minimal svg .apexcharts-inner.apexcharts-graphical {
	transform: translate(10px, 10px);
}

.p10 {
	padding: 10px;
}
.mt10 {
	margin-top: 10px;
}

.mt15 {
	margin-top: 15px;
}

/* tabler fix */
.badge {
	vertical-align: baseline !important;
}
.container-header .page-header {
	margin: 1rem 0 1rem !important;
}
.container-header {
	background-color: white;
	border-bottom: 1px solid #e6e8e9;
}

.footer {
	background-color: white;
	border-top: 1px solid #e6e8e9;
}

.avatar-sm {
	--tblr-avatar-size: 2.5rem;
}
.avatar.round {
	border-radius: 20px;
}

.dropdown-menu .badge {
	margin-left: 5px;
}

.primary-color {
	background-color: #4eb4b2;
}
.secondary-color {
	background-color: #ea97c1;
}
.selected {
	outline: 1px solid #1890ff !important;
}
.mark{
	background-color: #1890ff !important;
}
.h400 {
	height: 400px;
}
.fullHeight {
	height: 100%;
}
.m5 {
	margin: 5px;
}
.m10 {
	margin: 10px;
}
.mb5 {
	margin-bottom: 5px !important;
}
.mb10 {
	margin-bottom: 10px !important;
}

.mb15 {
	margin-bottom: 15px !important;
}

.mb18 {
	margin-bottom: 18px !important;
}
.mb20 {
	margin-bottom: 20px !important;
}
.mb30 {
	margin-bottom: 30px !important;
}
.mr5 {
	margin-right: 5px;
}
.mr3 {
	margin-right: 3px;
}
.mr10 {
	margin-right: 10px;
}
.mr15 {
	margin-right: 15px;
}
.mr20 {
	margin-right: 20px;
}

.ml3 {
	margin-left: 3px;
}
.ml5 {
	margin-left: 5px !important;
}
.ml10 {
	margin-left: 10px !important;
}
.ml20 {
	margin-left: 20px !important;
}
.ml30 {
	margin-left: 30px !important;
}
.mt3 {
	margin-top: 3px;
}
.mt5 {
	margin-top: 5px;
}
.mt10 {
	margin-top: 10px;
}
.mt15 {
	margin-top: 15px;
}
.mt20 {
	margin-top: 20px !important;
}
.mt30 {
	margin-top: 30px !important;
}
.ml10 {
	margin-left: 10px;
}
.w100p {
	width: 100%;
}
.overflow-y {
	overflow-y: auto;
}
.overflow-y-hidden {
	overflow-y: hidden;
}
.bg-process {
	background-color: #f7e43d !important;
}
.exo-muted-text{
	color: #BBB;
}
.exo-point {
	border-radius: 50%;
	height: 10px;
	display: inline-flex;
	width: 10px;
}
.inline {
	display: inline-flex;
}
.form-label{
	font-size: inherit !important;
	font-weight: inherit !important;
}
.block {
	display: block !important;
}

.grid {
	display: grid !important;
}

.cursor-pointer {
	cursor: pointer;
}

.disabled-btn {
	pointer-events: none;
    opacity: 0.4;
}

.required:after {
	content: ' *';
	color: red;
}

.red-text {
	color: #cf1322
}

.green-text {
	color: #5b8c00
}

.bold-text {
	font-weight: 700;
}

.space-between {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

/*for children group where we dont want the first child to have margin on the left side*/
.child-margin > *:not(:first-child) {
  margin-left: 10px;
}

.exo-row.light{
	background: #f7f7f7;
}

.exo-row {
	background: #f2f2f2;
	padding: 15px;
	margin-top: 10px;
	border-radius: 5px;
}

.borderless{
	border: none!important;
	box-shadow: none!important;
}

.bg-color-content{
	background-color: #f2f2f2;
}

.align-right{
	margin-left: auto;
	margin-right: 0px;
}

.align-child-right{
	display: flex;
	justify-content: flex-end;
}

.space {
  justify-content: space-between;
}

.transparent{
	background: transparent!important;
}

.h1{
	font-weight: 600;
    font-size: 20px;
}
.card-header{
	border: none!important;
}

.core-form-sheet .form-fieldset {
  background-color: #fff;
  border: none;
  padding: 0px;
}

.core-form-sheet .card-footer{
  border: none;
}

.ant-menu-title-content .circle{
	min-width: 9px;
}

