  .therapy{
    display: flex;
    height: 100%;
  }  
  .therapy-pane{
    margin: 10px;
    display: block;
    width: 40%
  }  
  .therapy-selection-details label{
    display: block;
    margin: 5px;
  }  
  .therapy .placeholder{
    font-style: italic;
    padding: 15px;
  }
  .therapy-action-buttons{
    display: grid;
  }
  .therapy-action-buttons .therapy-action-button{
    margin: 10px 0px;
    width: 100%;
  }
  .therapy-image{
    margin: 5px;
    height: 20px;
  }
  .therapy-actions{
    margin-bottom: 10px;
  }
  .therapy-header{
    margin: 10px;
    display: inline-flex;
  } 
  .therapy-status-txt{
    margin-left: 10px;
    color: #fff;
  }
  .therapy-status-dosis{
    margin-left: 10px;
    color: #fff;
  }
  .therapy .modal-grid .top{
    display: flex;    
  }
