.main-wrapper {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
}

.warning-text {
	color: #ff0000;
}

.bold-text {
	font-weight: bold;
}

.upper-transform {
	text-transform: uppercase;
}
