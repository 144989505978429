.hsp-status-container {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	margin-top: -60px;
	margin-bottom: 10px;
}

.button-container {
	display: flex;
	align-items: center;
	justify-content: center;
	margin-top: 20px;
	float: right;
	gap: 10px;
}

.agree-checkbox {
	margin-top: 70px;
	margin-left: 20px;
	display: flex;
}

.hsp-consent-item-title {
	font-size: 18px;
	font-weight: bold;
	margin-bottom: 10px;
	text-align: center;
}

.hsp-consent-item {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	margin-bottom: 20px;
	margin-left: 20px;
	margin-right: 20px;
}

.hsp-consent-item-content {
	text-align: justify;
}

.hsp-consent-container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	margin-top: 20px;
}

.hours-container {
	display: inline-grid;
	grid-template-columns: repeat(3, 1fr);
	grid-gap: 10px;
	margin-top: 20px;
	width: 100%;
}

.previous-next-buttons {
	display: flex;
	gap: 5px;
	align-items: center;
}

.hsp-profile-section {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	margin-top: 20px;
	margin-bottom: 20px;
}

.hsp-profile-subsection {
	text-align: center;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	margin-top: 20px;
	margin-bottom: 20px;
}

.hsp-profile-paragraph {
	text-align: justify;
}
.availability-container {
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	justify-content: center;
	margin-top: 0px;
	margin-bottom: 20px;
	gap: 30px;
}

.hsp-profile-info {
	display: flex;
}

.category-picker-card {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	margin-top: 20px;
	margin-bottom: 20px;
	width: 100%;
}

.category-picker-picker {
	margin-bottom: 20px;
}

.category-list .ant-list-item {
	justify-content: space-between;
	gap: 10px;
}

.category-select-card {
	display: flex;
	align-items: flex-start;
	justify-content: center;
	margin-top: 20px;
}

.hsp-level-select {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	width: 30%;	
	
}

.hsp-level-body {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	height: 200px;
	width: 250px;
}

.selected-level {
	background: #ffffff;
	background: -webkit-linear-gradient(bottom, #ffffff, #2665ff);
	background: -moz-linear-gradient(bottom, #ffffff, #2665ff);
	background: linear-gradient(to top, #ffffff, #2665ff);
}

.selected-level .ant-card-head-title {
	color: #fff;
}

.center-icons {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	margin-top: -20px;
	margin-bottom: 20px;
}

.mama-no-outline {
	height: 24px;
}

.mama-outline {
	font-size: 18px;
	paint-order: stroke;
	stroke: white;
	stroke-width: 3px;
	stroke-linecap: round;
	strokelinejoin: miter;
	font-weight: 800;
	height: 24px;
}

.hidden {
	display: none;
}
