.exo-task-symptom-alarms .ant-list-item{
    position: relative;
}

.exo-task-symptom-alarm-interventions{
    display: flex;
    
    background-color: #f9f9f9 !important;
    border-radius: 5px;
    margin-bottom: 5px;
    padding: 20px;
    padding-bottom: 0px;
}
