.exo-multi-line-chart svg {
  font-family: Sans-Serif, Arial;
}
.exo-multi-line-chart .line {
  stroke-width: 3;
  fill: none;
}

.exo-multi-line-chart .axis path {
  stroke: black;
}

.exo-multi-line-chart .text {
  font-size: 12px;
}

.exo-multi-line-chart .title-text {
  font-size: 12px;
}