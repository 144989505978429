.task-trello {
    margin-bottom: 100px;
    min-height: 700px;
}
.container-header-custom{
    height: 84px;
    margin: 20px 0;
    align-items: center;
    display: flex;
}
.page-title-container{
    display: flex;
    align-items: center;
}
.wrapper-header-custom{
    display: flex;
    justify-content: space-between;
    width: 100%;
}
.back-icon{
    cursor: pointer;
    margin-right: 25px;
}
.page-title-custom {
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 27px;
    line-height: 40px;
    /* identical to box height */
    color: #1E406A;
}