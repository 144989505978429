.navTitleContainer{
    justify-content: space-around;
    display: flex !important;
}


.navbar .navbar-nav .nav-link {
    justify-content: space-around !important;
    border-radius: 4px;
    max-width: 100%;
}

#navbar-menu {
    color: red;
}

.nav-item + .nav-item {
    margin-left: 50px;
}
