.exo-careplan .ant-drawer-body {
	padding: 0px 0px !important;
}

.exo-careplan-tabs .ant-tabs-content-holder {
	overflow: auto;
}

.exo-careplan-tabs .ant-tabs-content {
	height: calc(100vh - 260px);
}

.exo-careplan .timing-box {
	background: #f5f5f5;
	padding: 0px 7px 15px 5px;
	border-radius: 5px;
	margin: 20px 0px 0px 0px;
}

.ant-radio-group {
	display: inline-flex;
}
