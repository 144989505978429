
.line-align {
  display: flex;
}

.one {
  margin-left: 8px;
}

.two {
  margin-left: 20px;
}