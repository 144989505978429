.media-library .flexBox {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	gap: 1rem;
	margin: 1rem;
}

.media-library .grid {
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-gap: 1rem;
}

.media-library .outline:hover {
	outline: 1px solid #4eb4b2;
}

.media-library .selected {
	outline: 1px solid #ea97c1;
}

.media-library .font-sm {
	font-size: 12px;
}

.media-library .align-left {
	display: flex;
	flex-direction: column;
	align-items: left;
	gap: 1rem;
}

.media-library .avatar-uploader > .ant-upload {
	width: 128px;
	height: 128px;
}

.media-contents {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	gap: 1rem;
	margin: 1rem;
}

.top-options {
	display: flex;
}

.media-library-table
	> .ant-card
	> .ant-card-body
	> .ant-table-wrapper
	> .ant-spin-nested-loading
	> .ant-spin-container
	> .ant-table
	> .ant-table-container
	> .ant-table-header {
	top: -24px !important;
}
