.topbar{
  padding-right: 0px;
  padding-top: 10px;
  background-color: white;
}

.topbar-content.visible{
  visibility: visible;
  height: auto;
  opacity: 1;
}

.topbar-content{
  visibility: hidden;
  height: 0px;
  opacity: 0;
  transition: visibility 0s, opacity 0.5s linear;
} 

.topbar-label{
  margin-left: 7px;
  line-height: 24px;
}

.topbar-toogle{
  display: inline-flex;
}

.topbar-page .page-body {
	height: calc(100vh - 155px); /* spacing for footer and header PLUS Topbar*/
}