.line-align {
	display: flex;
}

.one {
	margin-left: 8px;
}

.two {
	margin-left: 20px;
}

.flex {
	display: flex;
	justify-content: space-between;
}

.drawerHeight {
	height: 900px;
}

.cms-card-container {
	width: 100%;
}

.cms-image {
	max-height: 3em;
	max-width: 120px;
}

.missing-image {
	height: 3rem;
	width: 3rem;
}
