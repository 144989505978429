.exo-task-editor .patient{
    display: flex;
}
.exo-task-editor .patient label{
    line-height: 20px;
}
.exo-task-editor .ant-collapse-header{
    text-align: center;
}
.exo-task-editor .state-selector{
    text-align: end;
}
.exo-task-editor .assign-to{
    display: flex;
}
.exo-task-editor .assign-to Button{
    margin-top: auto;
}
.exo-task-editor .task-title{
    font-size: 20px;
    font-weight: 500;
}
