/* .table-of-contents {
    display: flex;
    justify-content: center;
} */

.table-of-contents ul {
	list-style: none;
}

.table-of-contents a {
	display: inline-block;
	padding: 0.5em;
	margin: 0.5em;
	font-size: 0.9em;
	font-weight: bold;
	letter-spacing: 0.1em;
	line-height: 1.2em;
	cursor: pointer;
	color: grey !important;
	text-decoration: none;
}

.content-card {
	display: inline-block;
	background: #ffffff;
	box-shadow: 1px 4px 14px rgba(237, 228, 213, 0.6), inset 2px 2px 2px #ffffff;
	border-radius: 20px;
	padding: 10px;
	width: 100%;
	margin-top: 5px;
	margin-bottom: 5px;
	position: relative;
}

.external-card,
.attachment-card {
	display: inline-flex;
	background: #ffffff;
	box-shadow: 1px 4px 14px rgba(237, 228, 213, 0.6), inset 2px 2px 2px #ffffff;
	border-radius: 20px;
	padding: 10px;
	width: 100%;
	margin-top: 5px;
	margin-bottom: 5px;
	position: relative;
	align-items: center;
	justify-content: space-between;
}

.internal-article {
	display: flex;
	background: #ffffff;
	box-shadow: 1px 4px 14px rgba(237, 228, 213, 0.6), inset 2px 2px 2px #ffffff;
	border-radius: 20px;
	padding: 10px;
	width: 100%;
	margin-top: 5px;
	margin-bottom: 5px;
	position: relative;
	align-items: center;
	justify-content: space-between;
}

.internal-article > .internal-link {
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	text-decoration: none; /* No underlines on the link */
	z-index: 10; /* Places the link above everything else in the div */
	background-color: #fff; /* Fix to make div clickable in IE */
	opacity: 0; /* Fix to make div clickable in IE */
	filter: alpha(opacity=1); /* Fix to make div clickable in IE */
}

.internal-title {
	font-style: normal;
	font-weight: 700;
	font-size: 14px;
	line-height: 19px;
	display: flex;
	align-items: center;
}

.internal-image {
	max-width: 25% !important;
	border-radius: 20px;
	margin-right: 20px;
}

.external-tag {
	background: #9cd7f9;
	border-radius: 10.5px;
	color: white;
	padding: 2px;
	padding-right: 5px;
	padding-left: 5px;
	width: fit-content;
}

.external-title {
	font-style: normal;
	font-weight: 700;
	font-size: 14px;
	line-height: 19px;
	color: #1e406a;
	padding-top: 10px;
}

.external-card > .external-link {
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	text-decoration: none; /* No underlines on the link */
	z-index: 10; /* Places the link above everything else in the div */
	background-color: #fff; /* Fix to make div clickable in IE */
	opacity: 0; /* Fix to make div clickable in IE */
	filter: alpha(opacity=1); /* Fix to make div clickable in IE */
}

.section-container {
	background-color: #fcfbf7;
}

.attachment-icon {
	margin-right: 10px;
}

.internal-icon {
	margin-right: 10px;
	padding-left: 100px;
}

.attachment-tag {
	background: #9cd7f9;
	border-radius: 10.5px;
	color: white;
	padding: 2px;
	padding-right: 5px;
	padding-left: 5px;
	width: fit-content;
}

.attachment-title {
	font-style: normal;
	font-weight: 700;
	font-size: 14px;
	line-height: 19px;
	color: #1e406a;
	padding-top: 10px;
}

.attachment-card > .attachment-link {
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	text-decoration: none; /* No underlines on the link */
	z-index: 10; /* Places the link above everything else in the div */
	background-color: #fff; /* Fix to make div clickable in IE */
	opacity: 0; /* Fix to make div clickable in IE */
	filter: alpha(opacity=1); /* Fix to make div clickable in IE */
}

.custom-html-style > ul {
	background-color: #fae6e1;
	border-radius: 20px;
	padding: 10px;
}

.custom-html-style > ul > li {
	background: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fillRule='evenodd' clip-rule='evenodd' d='M7.7 15.4C11.9526 15.4 15.4 11.9526 15.4 7.7C15.4 3.44741 11.9526 0 7.7 0C3.44741 0 0 3.44741 0 7.7C0 11.9526 3.44741 15.4 7.7 15.4Z' fill='white'/%3E%3Cpath d='M4.71678 7.69546H10.5458' stroke='%231191CE' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round'/%3E%3Cpath d='M7.69999 4.71221V10.5412' stroke='%231191CE' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round'/%3E%3C/svg%3E%0A")
		no-repeat left 3px;
	padding: 0px 5px 0px 20px;
	list-style: none;
	margin: 0;
}

.conditional-content {
	background-color: #eef;
	border-radius: 5px;
	padding: 5px;
}
