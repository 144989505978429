.exo-patients-details .ant-drawer-body {
  padding: 0px 0px !important;
}

.center {
  display: block;
  margin: auto;
}

.text-black {
  color: black;
  margin: 0 auto;
}

.container-high {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 10vh;
}

