.exo-content-type-select .list {
	width: 100%;
}
.exo-content-type-select .list.small {
	width: 40%;
	max-width: 600px;
}
.exo-content-type-select .details {
	margin-left: 16px;
	width: auto;
	max-width: 60%;
}

.content-small {
	min-width: 45%;
	max-width: 45%;
}

.table-of-contents ul {
	background-color: inherit !important;
	background: none !important;
}

.table-of-contents li {
	list-style: inside !important;
	background: none !important;
}

.markdown-preview-content{
	padding: 10px;
}

.markdown-preview-content ul {
	background-color: #fae6e1;
	border-radius: 20px;
	padding: 10px;
}

.markdown-preview-content ul > li {
	background: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fillRule='evenodd' clipRule='evenodd' d='M7.7 15.4C11.9526 15.4 15.4 11.9526 15.4 7.7C15.4 3.44741 11.9526 0 7.7 0C3.44741 0 0 3.44741 0 7.7C0 11.9526 3.44741 15.4 7.7 15.4Z' fill='white'/%3E%3Cpath d='M4.71678 7.69546H10.5458' stroke='%231191CE' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round'/%3E%3Cpath d='M7.69999 4.71221V10.5412' stroke='%231191CE' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round'/%3E%3C/svg%3E%0A")
		no-repeat left 3px;
	padding: 0px 5px 0px 20px;
	list-style: none;
	margin: 0;
}
