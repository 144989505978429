.app-navbar.navbar .navbar-nav .show > .nav-link,
.app-navbar.navbar .navbar-nav .active > .nav-link,
.app-navbar.navbar .navbar-nav .nav-link.active,
.app-navbar.navbar .navbar-nav .dropdown-item.active,
.app-navbar.navbar .navbar-nav .dropdown-item:active {
	border-radius: 0px;
	color: #000 !important;
	background-color: #4eb4b2 !important;
}

.app-navbar .navbar-clinic {
	background: #ffffff !important;
	color: rgba(255, 255, 255, 0.7) !important;
}

.app-navbar.navbar {
	border-right: 1px solid #e6e8e9;
	background-color: #fff;
}

.app-navbar .nav-item.dropdown .dropdown-item {
	color: #000;
}

.app-navbar .nav-link:not(.show).dropdown-toggle:after {
	-webkit-transform: rotate(225deg);
	transform: rotate(225deg);
}

.app-navbar.navbar .nav-item.dropdown .dropdown-item {
	color: #646d76;
}

.app-navbar.navbar .navbar-nav .nav-link:hover,
.app-navbar.navbar .navbar-nav .nav-link:focus,
.app-navbar.navbar .nav-item .dropdown-item:hover,
.dropdown-item:focus {
	color: #000;
	text-decoration: none;
	background-color: rgba(101, 109, 119, 0.06);
}

.app-navbar.navbar .navbar-nav .nav-link:hover svg,
.app-navbar.navbar .nav-link.dropdown-toggle.show svg,
.app-navbar.navbar .nav-item.active svg,
.app-navbar.navbar .dropdown-item.active svg,
.app-navbar.navbar .dropdown-item:hover svg {
	fill: #000;
}
.app-navbar.navbar svg {
	fill: #646d76;
}
.app-navbar.navbar .dropdown-item.disabled,
.app-navbar.navbar .dropdown-item:disabled {
	color: #c6cad0 !important;
}
.ant-layout-sider-children {
	border-right: 1px solid #e3e3e3;
}
.sider-menu-action {
	border-top: 1px solid #e3e3e3;
}
.ant-menu-item a:hover {
	color: #1a569d !important;
}
.ant-menu-vertical > .ant-menu-item,
.ant-menu-vertical-left > .ant-menu-item,
.ant-menu-vertical-right > .ant-menu-item,
.ant-menu-inline > .ant-menu-item,
.ant-menu-vertical > .ant-menu-submenu > .ant-menu-submenu-title,
.ant-menu-vertical-left > .ant-menu-submenu > .ant-menu-submenu-title,
.ant-menu-vertical-right > .ant-menu-submenu > .ant-menu-submenu-title,
.ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
	height: 50px;
	line-height: 50px;
}
.ant-menu-sub > .ant-menu-item:hover,
.ant-menu-sub > .ant-menu-item-selected:hover {
	background-color: #cfe5fa !important;
}
.sidebar-logo svg,
.logo-collapsed svg,
.menu-container svg,
.user-action-menu svg,
.sider-icon {
	fill: #1890ff;
	width: 28px;
	height: auto;
}
.user-action-menu svg {
	height: 27px;
}
.ant-menu.ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title,
.ant-menu.ant-menu-inline-collapsed > .ant-menu-item {
	left: -5px;
}
.ant-menu-item.sub {
	margin-top: 20vh;
}
@media (max-height: 850px) {
	.ant-menu-item.sub {
		margin-top: 5px;
	}
}

@media (max-height: 695px) {
	.ant-menu-vertical > .ant-menu-item {
		height: 40px;
	}
	.ant-menu-vertical > .ant-menu-submenu > .ant-menu-submenu-title {
		height: 39px;
		line-height: 50px;
	}

	.ant-menu-inline > .ant-menu-item {
		height: 39px;
		line-height: 50px;
	}
}
