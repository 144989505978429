.exo-video{
	background: #000;
    padding: 10px;
	display: block;
}

.exo-video-screen{
	max-height: 45%;
	font-size: larger;
}

.exo-video-header{
    border-radius: 5px;
    background: #68bcb9;
    padding: 20px;
    margin-bottom: 10px;
	font-size: larger;
	line-height: 35px;
	max-height: 80px;
	display: flex;
}

.exo-video-menu{
	margin-right: 30px;
}

.exo-video-title{
	color: black;
}