.taskcard > .ant-card {
	border-radius: 8px;
	border: 1px solid #e8e8e8;
	margin-bottom: 18px;
}

.status-container {
	/* background: rgb(230, 117, 117); */
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.title-header {
	/* background: rgb(142, 142, 230); */
	display: flex;
	margin-top: 4px;
	margin-bottom: 10px;
}

.title-header span {
	font-weight: 500;
	font-size: 14px;
	line-height: 21px;
	color: #1e406a;
}

.date {
	font-weight: 500;
	font-size: 10px;
	line-height: 15px;
	color: #1e406a;
	opacity: 0.3;
}

.task-status {
	font-weight: 500;
	font-size: 10px;
	line-height: 15px;
	text-transform: uppercase;
}

.patient-name {
	font-weight: 600;
	font-size: 12px;
	line-height: 18px;
	color: #1e406a;
}

.action-menu-container {
	margin-top: 14px;
	width: 100%;
	height: 80px;

	background: #e8f5fa;
	border-radius: 8px;

	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 10px 9px;
}

.taskcard > .ant-card > .ant-card-body {
	padding: 20px 16px !important;
}
.action-menu-row{
	display: flex;
	width: 330px
}

.action-menu-details {
	margin: auto;
	display: flex;
	flex-direction: column;
}

.details-title {
	font-family: Poppins;
	font-weight: 400;
	font-size: 9px;
	line-height: 13px;
	color: #1e406a;
}

.details-data {
	font-family: Poppins;
	font-weight: 600;
	font-size: 9px;
	line-height: 13px;
	color: #1e406a;
}

.action-primary-btn {
	height: 28px !important;
	padding: 8px 28px !important;
	font-size: 9px !important;
	border-radius: 5px !important;
}

.action-btns {
	display: inline-block;
}

.ant-btn-danger {
	background: #ffa39f !important;
	border-color: #ffa39f !important;
}

.status-badge > .ant-badge > .ant-badge-status-text {
	font-family: Poppins;
	font-style: normal;
	font-weight: 600;
	font-size: 15px;
	line-height: 22px;
	/* identical to box height */
	text-transform: uppercase;

	color: #1e406a;
}

.hidden {
	display: none;
}
