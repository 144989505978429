.card-margin {
  margin-bottom: 20px;
}

.space-between {
  justify-content: space-between;
}

.label-spacing {
  margin-top: 30px;
}

.spaced {
  margin-bottom: 3.5px;
}

.data-check {
  display: flex;
}

.select-text-center {
  text-align: center;
  text-align-last: center;
}

.single-option {
  text-align: left;
}

.details-event .ant-select-selector{
  padding: 0px !important;
}