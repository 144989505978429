.notification-high {
	background-color: #fff;
}

.notification-item{
	padding: 10px;
	border-bottom: 1px solid #f1f1f1;
}

.exo-notification-menu{
	padding: 0px;
}