.sticky {
  position: sticky;
  top: 0;
  z-index: 999;
  background: #fbfbfb;
  width: 100%;
  height: 100%;
}

.steps > a {
  cursor: auto;
}