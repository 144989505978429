.symptom-subscores{
    font-size: smaller;
    margin-top: 10px;
    display: grid;
}
.symptom-subscore-title{
    width: 100px;
}
.symptom-subscore-container{
    max-width: 200px;
    position: relative;
    margin-bottom: 5px;
}
.symptom-subscore {
    width: 100px;
    border-radius: 5px;
    background: rgb(246,255,56);
    background: linear-gradient(90deg, rgba(246,255,56,1) 0%, rgba(253,29,29,1) 100%, rgba(252,176,69,1) 100%);
}
.symptom-subscore-overlay{
    position: absolute;
    background-color: #f4f4f4;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    height: 19px;
    right: 0px;
}
.symptom-subscore-value-title{
    position: absolute;
    right: 55px;
}