.exo-matrix-chat-rooms{
	height: 100%;
	overflow-y: auto;
}
.exo-matrix-chat-history-header{
    height: 50px;
    line-height: 30px;
    padding: 10px;
}
.exo-matrix-chat-history-messages{
    padding: 10px;
	position: relative;
}
.exo-matrix-chat-history-close{
    position: absolute;
    top: 10px;
	right: 10px;
}
.exo-matrix-chat-history-footer{
    padding: 10px;
	position: absolute;
	bottom: 0px;   
	height: 60px;
	background-color: #f2f2f2;
	width: 100%;
}

.exo-matrix-chat-history-footer .ant-input{
	border-radius: 10px;
}

.exo-matrix-chat-history-footer > span{
	display: flex!important;
}
.exo-matrix-chat-history-footer > input{
	border-radius: 6px;
}
.exo-matrix-chat-history-input-controls{
	line-height: auto;
	padding: 10px;
	margin: 0px 10px 0px 10px;
}
.exo-matrix-chat-history .others-message > div{
	text-align: end;
	float: right;
	border-bottom-right-radius: 0px;
	background-color: #f2f2f2;
}
.exo-matrix-chat-history .own-message > div{
	border-bottom-left-radius: 0px;
	background-color: #bbdeff;
}
.exo-matrix-chat-history .action > div{
	background-color: transparent;
    border-bottom: 1px solid #000;
    border-radius: unset;
}
.exo-matrix-chat-history{
	display: contents;
}
.exo-matrix-chat-history .ant-list-split .ant-list-item{
    padding: 2px 0px 2px 0px;
	border: none;
}
.exo-matrix-chat-history .ant-list-item-meta-content{
	width: fit-content;
	max-width: fit-content;
	border-radius: 15px;
	padding: 5px 10px;
}
.exo-matrix-chat-history .ant-list-item-meta{
	display: block;
}

.exo-matrix-chat-rooms .ant-list-header{
	padding: 8px 24px;
}

.exo-matrix-chat-rooms .ant-list-split .ant-list-header {
    border-bottom: 1px solid #e3e3e3;
	height: 50px;
}

.exo-matrix-chat-rooms{
	border-left: 1px #e3e3e3 solid;
}

.exo-matrix-chat-rooms .ant-list-item{
	padding: 12px 12px;
	background-color: #fafafa;
	border-bottom: 1px solid #e3e3e3;
}

.exo-matrix-chat-rooms-list-open{
	background-color: #e4f1ff !important;
}

.exo-matrix-chat-rooms-list-patient{
	font-weight: bold;
}