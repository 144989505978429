.performance-chart{
    display: inline;
}
.performance-chart .bars{
    width: 100%;
    display: flex;
}
.performance-chart .bars .tick{
    height: 20px;
    width: 5px;
    margin-right: 1px;
    border-radius: 2px;
    background-color: aqua;
}
.performance-chart .performance{
    margin-top: 1px;
    text-align: left;
}