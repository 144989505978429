.scroll-view {
    height: 100vh;
	overflow: auto;
}



.button-container {
	display: flex;
	align-items: center;
	justify-content: center;
	margin-top: 20px;
	float: right;
	gap: 10px;
}

.agree-checkbox {
	margin-top: 70px;
	margin-left: 20px;
	display: flex;
}

.user-consent-item-title {
	font-size: 18px;
	font-weight: bold;
	margin-bottom: 10px;
	text-align: center;
}

.user-consent-item {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	margin-bottom: 20px;
	margin-left: 20px;
	margin-right: 20px;
}

.user-consent-item-content {
	text-align: justify;
}

.user-consent-container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	margin-top: 20px;
}

.hours-container {
	display: inline-grid;
	grid-template-columns: repeat(3, 1fr);
	grid-gap: 10px;
	margin-top: 20px;
	width: 100%;
}

.previous-next-buttons {
	display: flex;
	gap: 5px;
	align-items: center;
}

.user-profile-section {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	margin-top: 20px;
	margin-bottom: 20px;
}

.user-profile-subsection {
	text-align: center;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	margin-top: 20px;
	margin-bottom: 20px;
}

.user-profile-paragraph {
	text-align: justify;
}
.availability-container {
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	justify-content: center;
	margin-top: 0px;
	margin-bottom: 20px;
	gap: 30px;
}

.user-profile-info {
	display: flex;
}

.category-picker-card {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	margin-top: 20px;
	margin-bottom: 20px;
	width: 100%;
}

.category-picker-picker {
	margin-bottom: 20px;
}

.category-list .ant-list-item {
	justify-content: space-between;
	gap: 10px;
}

.category-select-card {
	display: flex;
	align-items: flex-start;
	justify-content: center;
	margin-top: 20px;
}

.user-level-select {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	width: 30%;
	margin-left: 35%;

	/* margin-bottom: 20px;
	gap: 20px;
	margin-left: 15%;
	margin-right: 15%; */
}

.user-level-body {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	height: 200px;
	width: 250px;
}

.selected-level {
	background: #ffffff;
	background: -webkit-linear-gradient(bottom, #ffffff, #2665ff);
	background: -moz-linear-gradient(bottom, #ffffff, #2665ff);
	background: linear-gradient(to top, #ffffff, #2665ff);
}

.selected-level .ant-card-head-title {
	color: #fff;
}

.center-icons {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	margin-top: -20px;
	margin-bottom: 20px;
}

.mama-no-outline {
	height: 24px;
}

.mama-outline {
	font-size: 18px;
	paint-order: stroke;
	stroke: white;
	stroke-width: 3px;
	stroke-linecap: round;
	strokelinejoin: miter;
	font-weight: 800;
	height: 24px;
}

.hidden {
	display: none;
}
