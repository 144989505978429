.exo-list.inLine{
  display: inline-flex;
}

.exo-list dl.inLine{
  display: inline;
  margin: 0px 10px;
}
.exo-list dl {
  display: grid;
  grid-template-columns: 33% auto;
}
.exo-list dt{
  grid-column: 1;
}


.exo-list dd {
  grid-column: 2;
}