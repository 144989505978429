.careteam-profile-section {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	margin-top: 20px;
	margin-bottom: 20px;
}

.careteam-sub-subsection {
	margin-bottom: 20px;
	text-align: center;
}

.careteam-body {
	text-align: center;
}

.careteam-participants .ant-list-item-meta-content {
	text-align: center;
}
