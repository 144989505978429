.page-top-bar{
  background-color: white;
}

.page-top-bar .ant-select-selector{
  height: 50px !important;
}

.page-search{
  height: 100%;
  display: flex;
}