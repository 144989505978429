.exo-assessment-type-select,
.exo-assessment-type-select .list{
    width: 100%;
}
.exo-assessment-type-select .list.small{
    width: 65%;
}
.exo-assessment-type-select .details{
    margin-top: 24px;
    width: 35%;
}