.comparison-chart{
    width: 100%
}


.boxplot {
    font: 10px sans-serif;
}
  
.boxplot line,
.bboxplotx rect{
    stroke: #000;
    stroke-width: 0.5px;
}
.boxplot rect {
    fill: #98abc594;
}
.boxplot circle {
    fill: #94949461;
    stroke: #00000061;
}
.boxplot .range-line {
    fill: #9e9e9e;
    stroke-dasharray: 3,3;
}

.boxplot .center {
    stroke-dasharray: 3,3;
}

.boxplot .outlier {
    fill: none;
    stroke: #000;
}

.boxplot .axis {
    font: 12px sans-serif;
}

.boxplot .axis path,
.boxplot .axis line {
    fill: none;
    stroke: #000;
    shape-rendering: crispEdges;
}

.boxplot .x.axis path { 
    fill: none;
    stroke: #000;
    shape-rendering: crispEdges;
}

.boxplot .points.selected{
    fill: #ff9800;
}

.boxplot .points.highlight{
    fill: #01dcff;
}


.details{
    font-size: 11px;
    font-family: monospace;
    text-align: left;
}

.details table{
    margin: 15px 25px;
}

.chart-btn{
    position: absolute;
    top: 10px;
    right: 10px;
    height: 30px;
    width: 30px;
    border: none;
}
.chart-btn:nth-of-type(2){
    top: 50px;
}
