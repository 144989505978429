.thumbnail-container {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.action-menu {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.action-menu-content {
	display: flex-end;
	justify-content: space-between;
	align-items: center;
}

.disabled {
	opacity: 0.5;
}

.subcategory-image {
	height: 3em;
	margin-left: 10px;
}

.content-category-image {
	height: 3em;
	margin-left: 30px !important;
}

.category-text-style {
	font-weight: bold;
	font-size: 1rem;
}

.subcategory-text-style {
	margin-left: 10px;
}

.content-category-text-style {
	margin-left: 30px;
	font-size: 0.8rem;
}

.ant-table-row-level-2 > td {
	padding: 8px 8px !important;
}

.ant-table-row-level-1 > td {
	background-color: #ebf7fb !important;
	padding: 8px 8px !important;
}
