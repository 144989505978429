.recipe{
  height: inherit;
  position: relative;
}
.recipe .approve{
  position: absolute;
  left: 430px;
  top: 250px;
}
.recipe-img{
  max-height: 400px;
}