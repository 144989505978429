.notes {
	display: block;
	width: 100%;
}
.notes .placeholder {
	font-style: italic;
}
.notes .header-wrapper{
	justify-content: space-between;
}
.notes .text-input{
	height: 272px;
    border-radius: 3px;
    padding: 5px;
	margin-bottom: 10px;
	border-color: #e7eaef;
	width: 100%;
	height: 36px;
}
.notes button {
	max-height: 40px;
	width: 100%;
}
.notes{
	display: relative;
}
.notes-new {
	display: grid;
}
.notes-list {
}

.notes-list-list{
	height: 388px!important;
}

.notes .form-label{
    top: 25px;
}
