.centered {
	display: flex;
	justify-content: center;
	align-items: center;
	/* spacing for footer and header */
}

.flex-column {
	flex-direction: column;
	gap: 1rem;
}

.img-background-container {
	background-color: white;
	margin-bottom: 50px;
	height: 200px;
	align-items: center;
	display: flex;
	justify-content: center;
}

.library-button-container {
	width: 200px;
}
