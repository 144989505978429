.ant-page-header-footer{
  margin-top: 0px !important;
}
.ant-page-header-content{
  padding-top: 0px;
}
.ant-page-header-content .exo-row{
  margin-top: 5px;
}

.exo-patient .ant-page-header{
  padding-bottom: 0px !important;
}

.badge-grey sup{
  background: grey;
}

.badge-black sup{
  background: black;
}