.line-align {
	display: flex;
}

.one {
	margin-left: 8px;
}

.two {
	margin-left: 20px;
}

.flex {
	display: flex;
	justify-content: space-between;
}

.drawerHeight {
	height: 900px;
}

.cms-card-container{
	width: 100%;
}

.cms-image{
	height: 70px;
	width: auto;
}

.ant-table-row-level-1 {
	background-color:#daedf4;
	height: 20px;
}

.ant-table-row-level-1 .ant-table-cell-with-append {
	background-color:#daedf4;
	padding-right: 20px;
}

.thumbnail-container {
	display: flex;
	justify-content: space-between;
	align-items: center;

}

.media-container {
	display: flex;
    justify-content: space-between;
    align-items: center;
}

.up-down-icons {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;

}
