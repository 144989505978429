.exo-select{
  min-width: 150px;
}

.exo-select .label-spacing{
  margin-top: 5px;
}

.overlap {
  z-index: 100;
}



