.appointments-card-container {
    width: 100%;
}

.appointments-card-container>.ant-card>.ant-card-body {
    padding: 0 24px !important;
    margin-top: 15px;
}

.ant-btn-transparent {
    padding: 10px 28px !important;
    margin-right: 15px !important;
}

.ant-btn-transparent span {
    color: #4CBBF9 !important;

}


.df {
    display: flex;
    align-items: center;
}

.df span {
    margin-left: 17px;
}

.type-txt {
    text-transform: capitalize;
}

.status-tag {
    border-radius: 4px;
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 10px;
    line-height: 15px;
    text-align: center;
    padding: 5px 10px;
    text-transform: uppercase;


}

.appointment-action-btn {
    height: 25px !important;
    width: 25px !important;
}

.appointments-patient-badge span {
    width: 8px !important;
    height: 8px !important;
}

.appointments-patient-badge {
    margin-left: 30px;
}