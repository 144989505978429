.space {
  justify-content: space-between;
}

.center {
  display: block;
  margin: auto;
}

.text-black {
  color: black;
  margin: 0 auto;
}

.container-high {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 10vh;
}

.text-highlight {
  font-weight: bold;
}
