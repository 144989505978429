.exo-list .ant-list-item{
  background-color: #f9f9f9 !important;
  border-radius: 5px;
  margin-bottom: 5px;
  border-bottom: none;
}

.exo-list .ant-list-item-meta{
  margin: 0px 20px;
  min-width: 150px;
}